import styled from 'styled-components';
import { SM_AND_UP_QUERY } from '@/breakpoints';
import { fontStyle } from '@/styles/common';

export const StyledWrapper = styled.div`
  background: #141623;
  padding: 60px 24px;
  overflow: hidden;
  position: relative;
`;

export const StyledTitle = styled.div`
  ${fontStyle('48px', '700')};
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Cormorant', serif;
  margin-bottom: 10px;
  text-align: center;

  @media ${SM_AND_UP_QUERY} {
    ${fontStyle('64px', '700')};
    line-height: 96px;
  }
`;

export const StyledText = styled.div`
  ${fontStyle('20px', '700')};
  color: #4c89ff;
  margin-left: 16px;

  @media ${SM_AND_UP_QUERY} {
    ${fontStyle('24px', '700')};
  }
`;

export const StyledContactWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  margin-top: 34px;

  a {
    text-decoration: none;
  }

  @media ${SM_AND_UP_QUERY} {
    display: flex;
    justify-content: space-between;
    max-width: 939px;
    width: 100%;
  }
`;

export const StyledCopyright = styled.div`
  ${fontStyle('10px', '400')};
  color: #d2d2d2;
  text-align: center;
  margin-top: 127px;
`;

export const StyledZindex = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
