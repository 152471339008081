import styled from 'styled-components';
import { SM_AND_UP_QUERY } from '@/breakpoints';
import { fontStyle } from '@/styles/common';

export const StyledWrapper = styled.div<{ isRow?: boolean }>`
  background: #141623;
  padding: 60px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${SM_AND_UP_QUERY} {
    flex-direction: ${({ isRow }) => (isRow ? 'row' : 'column')};
    align-items: ${({ isRow }) => (isRow ? 'flex-start' : 'center')};
  }
`;

export const StyledContent = styled.div`
  @media ${SM_AND_UP_QUERY} {
    display: flex;
    align-items: flex-start;
    max-width: 1194px;
    width: 100%;
    margin: 0 auto;

    & > div:first-child {
      margin-right: 67px;
      min-width: 339px;
      margin-right: 20px;
    }

    & > div:last-child {
      flex: 1;
    }
  }
`;

export const StyledTitle = styled.div`
  ${fontStyle('48px', '700')};
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Cormorant', serif;
  margin-bottom: 10px;
  text-align: center;

  @media ${SM_AND_UP_QUERY} {
    ${fontStyle('64px', '700')};
    line-height: 96px;
  }
`;

export const StyledText = styled.div<{ mb?: string; textAlign?: string }>`
  ${fontStyle('14px', '400')};
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: ${({ mb }) => mb};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  line-height: 24px;
  width: 100%;

  @media ${SM_AND_UP_QUERY} {
    ${fontStyle('24px', '400')};
    line-height: 36px;
    width: 940px;
  }
`;
