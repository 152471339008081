/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Row } from '@/styles/common';
import {
  StyledBannerSection,
  StyledClaimButton,
  StyledClaimLink,
  StyledClaimSection,
  StyledClaimTitle,
  StyledInput,
  StyledLinkRow,
  StyledZindex,
} from './Styles';

type Props = {
  onClaim: () => void;
  setClaimCode: (text: string) => void;
};

const Banner = ({ onClaim, setClaimCode }: Props) => {
  const [totalClaim, setTotalClaim] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios('https://api-zogq3k52ja-uc.a.run.app/api/v1/cryptoTitans/totalSupply');
        const { totalSupply } = data.data;
        setTotalClaim(totalSupply);
      } catch (error) {
        // error
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <StyledBannerSection>
        <div>
          <div>Crypto Titans NFT</div>
          <div
            dangerouslySetInnerHTML={{
              __html: '精準捉住加密貨幣漲跌！<br>破解理想主義者、網路天才與詐欺犯等加密巨鱷合作創造的金錢浪潮',
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: '泰鑫淼MARKUS THIELEN 著<br>吳忌寒JIHAN WU 推薦',
            }}
          />
        </div>
      </StyledBannerSection>
      <StyledZindex>
        <StyledClaimSection>
          <StyledClaimTitle>{`已領取數量 : {${totalClaim}/1600}`}</StyledClaimTitle>
          <Row mb="16px">
            <StyledInput placeholder="請輸入八位數序號" onChange={(e) => setClaimCode(e.target.value)} />
            <StyledClaimButton onClick={onClaim}>領取</StyledClaimButton>
          </Row>
          {/* <StyledLinkRow>
              <StyledClaimLink>OpenSea</StyledClaimLink>
              <StyledClaimLink>Contract Address</StyledClaimLink>
            </StyledLinkRow> */}
        </StyledClaimSection>
      </StyledZindex>
    </>
  );
};

export default Banner;
