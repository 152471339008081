import styled from 'styled-components';
import { XS_QUERY } from '@/breakpoints';
import { Row, fontStyle } from '@/styles/common';

export const StyledHeader = styled.div`
  width: 100%;
  padding: 19px 24px;
  background: #141623;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
`;

export const StyledContent = styled(Row)`
  justify-content: space-between;
  max-width: 1180px;
  width: 100%;
  margin: 0 auto;
`;

export const StyledTitle = styled.div`
  ${fontStyle('24px', '700')};
  font-family: 'Cormorant', serif;
  font-optical-sizing: auto;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledHamburger = styled.div<{ isActive: boolean }>`
  display: none;
  width: 30px;
  height: 3px;
  border-radius: 10px;
  position: relative;
  background-color: ${({ theme, isActive }) => (isActive ? 'transparent' : theme.colors.white)};

  &::before {
    content: '';
    display: block;
    width: 30px;
    height: 3px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    left: 0;
    top: ${({ isActive }) => (isActive ? '2px' : '-10px')};
    transform: ${({ isActive }) => (isActive ? 'rotate(45deg)' : 'rotate(0deg)')};
  }

  &::after {
    content: '';
    display: block;
    width: 30px;
    height: 3px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    left: 0;
    top: ${({ isActive }) => (isActive ? '2px' : '10px')};
    transform: ${({ isActive }) => (isActive ? 'rotate(135deg)' : 'rotate(0deg)')};
  }

  @media ${XS_QUERY} {
    display: block;
  }
`;

export const StyledListWrapper = styled.div<{ isActive: boolean }>`
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-gap: 24px;
  align-items: center;

  @media ${XS_QUERY} {
    padding: 40px 24px 0 24px;
    grid-gap: 0;
    display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.black};
  }
`;

export const StyledListItem = styled.a`
  ${fontStyle('16px', '500')};
  color: #4c89ff;
  text-decoration: none;

  @media ${XS_QUERY} {
    margin-bottom: 32px;
    text-align: center;
    ${fontStyle('20px', '700')};
  }
`;

export const StyledListRedeem = styled.div`
  ${fontStyle('16px', '500')};
  padding: 8px;
  border-radius: 4px;
  border: solid 1px #4c89ff;
  color: #4c89ff;
  text-decoration: none;
  width: 100%;
  cursor: pointer;

  @media ${XS_QUERY} {
    padding: 16px;
    text-align: center;
    ${fontStyle('20px', '500')};
  }
`;
