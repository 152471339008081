/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { signMessage as joyIdSignMessage } from '@joyid/evm';
import { Loading } from '@redreamerlab/uikit';
import { useWeb3React } from '@web3-react/core';
import axios, { AxiosError } from 'axios';
import effect from '@/animation_lleyso3g.json';
import { SuccessModal } from '@/components/SuccessModal';
import { joyIDProvider } from '@/configs/joyIDConfig';
import { useJoyIdWallet } from '@/hooks/useJoyIdWallet';
import { useOKXWallet } from '@/hooks/useOKXWallet';
import { Banner } from './Banner';
import { BindingModal } from './BindingModal';
import { Footer } from './Footer';
import { Header } from './Header';
import { ParallaxStar } from './ParallaxStar';
import { Sections } from './Sections';

const Home = () => {
  const { account } = useWeb3React();
  const { joyIdAddress } = useJoyIdWallet();
  const { okxAddress } = useOKXWallet();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [claimCode, setClaimCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<{ title: string; message: string }>({ title: '', message: '' });

  const address = account || joyIdAddress || okxAddress;

  const claim = async () => {
    try {
      setIsLoading(true);
      await axios.post('https://api-zogq3k52ja-uc.a.run.app/api/v1/cryptoTitans/claim', {
        address,
        code: claimCode,
      });
      setSuccessModal({ title: 'Success', message: '領取成功' });
    } catch (error: AxiosError | any) {
      setSuccessModal({ title: 'Error', message: '序號錯誤' });
    } finally {
      setIsLoading(false);
    }
  };

  const claimHandler = () => {
    if (claimCode) {
      if (!address) {
        setOpenModal(true);
      } else {
        claim();
      }
    }
  };

  useEffect(() => {
    if (address) {
      setOpenModal(false);

      if (claimCode) {
        claim();
      }
    }
  }, [account, joyIdAddress, okxAddress]);

  return (
    <>
      <Loading effect={effect} isLoading={isLoading} style={{ width: 300, height: 300 }} />
      <Header address={address || ''} onConnectWallet={() => setOpenModal(true)} />
      <ParallaxStar />
      <Banner onClaim={claimHandler} setClaimCode={(text) => setClaimCode(text)} />
      <Sections />
      <Footer />
      <BindingModal opened={openModal} onClose={() => setOpenModal(false)} />
      <SuccessModal
        opened={!!successModal.title}
        title={successModal.title}
        message={successModal.message}
        onClose={() => setSuccessModal({ title: '', message: '' })}
      />
    </>
  );
};

export default Home;
