import React, { useState } from 'react';
import useApplicationContext from '@/providers/applicationContext';
import {
  StyledContent,
  StyledHamburger,
  StyledHeader,
  StyledListItem,
  StyledListRedeem,
  StyledListWrapper,
  StyledTitle,
} from './Styles';

type Props = {
  address: string;
  onConnectWallet: () => void;
};

const lists = [
  {
    id: 'About',
    name: '關於',
    link: '#About',
  },
  {
    id: 'FAQ',
    name: '常見問題',
    link: '#FAQ',
  },
  {
    id: 'Contact',
    name: '聯繫我們',
    link: '#Contact',
  },
];

const Header = ({ address, onConnectWallet }: Props) => {
  const { deactivateWallet } = useApplicationContext();

  const [isActive, setIsActive] = useState<boolean>(false);

  const handleUnbind = async () => {
    await deactivateWallet();
  };

  return (
    <StyledHeader>
      <StyledContent>
        <StyledTitle>Crypto Titans NFT</StyledTitle>
        <StyledHamburger isActive={isActive} onClick={() => setIsActive(!isActive)} />
        <StyledListWrapper isActive={isActive}>
          {lists.map((item) => (
            <StyledListItem key={item.id} href={item.link} onClick={() => setIsActive(false)}>
              {item.name}
            </StyledListItem>
          ))}
          {address ? (
            <StyledListRedeem onClick={handleUnbind}>斷開連接錢包</StyledListRedeem>
          ) : (
            <StyledListRedeem onClick={onConnectWallet}>連接錢包</StyledListRedeem>
          )}
        </StyledListWrapper>
      </StyledContent>
    </StyledHeader>
  );
};

export default Header;
