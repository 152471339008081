import React from 'react';
import './styles.sass';

const ParallaxStar = () => (
  <>
    <div id="stars" />
    <div id="stars2" />
    <div id="stars3" />
  </>
);

export default ParallaxStar;
