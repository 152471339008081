import Button from '@mui/material/Button';
import styled from 'styled-components';

export const CustomMuiButton = styled(Button)`
  &&& {
    width: 100%;
    padding: 8px 0;
    background: #4c89ff;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;
    text-transform: none;
    margin-bottom: 18px;
  }
`;
