/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { StyledIconImg } from '@/styles/common';
import { diamondIcon } from '@/styles/imgMapping';
import { StyledContent, StyledText, StyledTitle, StyledWrapper } from './Styles';

const Sections = () => (
  <>
    <StyledWrapper>
      <StyledTitle>暢銷冠軍</StyledTitle>
      <StyledText mb="10px">10x Helping EVERYONE to make better crypto decsions</StyledText>
      <StyledIconImg src={diamondIcon} width="16px" mb="10px" />
      <StyledText mb="10px">獲得 Coinbase, Draper, Bitdeer, Wintermute, Animoca 讚譽</StyledText>
      <StyledIconImg src={diamondIcon} width="16px" mb="10px" />
      <StyledText
        dangerouslySetInnerHTML={{
          __html:
            '2030年預估會有十億人參與加密貨幣的投資。部分分析師預測2024年比特幣的價格也有望突破十五萬美元。不過依過去的發展史來看，加密貨幣的價格總是在波動中成長！那趨動它價格的動力是什麼？<br><br>2011年以來，比特幣面臨四次多頭及一次空頭。價格在暴跌之後，總是價暴漲，一次又一次的漲破前高。推動它上漲的原因，在於一群死忠支持者：程式開發人員、礦工、交易員、外部投資人。不停的創造足以推升價格的環境或機制。如馬斯克不停的在社群媒體行銷狗狗幣，造成它的大漲。而當利多出盡，或是出現利空消息（經常是詐欺案），則造成價格大跌。<br><br>本書說明歷來重要加密巨鱷，如何改進交易機制、積極行銷，以促進價格及市場成長的歷程。你可以透過本書成為他們的一分子，進而掌握價格的波動。',
        }}
      />
    </StyledWrapper>
    <StyledWrapper isRow id="About">
      <StyledContent>
        <StyledTitle>關於我們</StyledTitle>
        <StyledText textAlign="left">
          泰鑫淼（Markus Thielen）是加密貨幣分析機構 10x Research 的執行長兼研究主管。他曾擔任數位資產投資公司
          Matrixport 的研究與戰略主管。泰鑫淼還是 Jomon 投資管理公司（Jomon Investment
          Management）的創始人兼投資長。之前，泰鑫淼曾在萬世資本（Millennium Capital Partners）和摩根大通集團（JP Morgan
          Investment Group）擔任投資組合經理。他的職業生涯始於摩根史坦利（Morgan
          Stanley），他在那裡創建並負責管理亞洲地區量化與衍生品戰略部。泰鑫淼擁有 ESCP 歐洲商學院（ESCP Business
          School）學位和劍橋大學（University of Cambridge）永續金融（Sustainable Finance）證書。
        </StyledText>
      </StyledContent>
    </StyledWrapper>
    <StyledWrapper isRow id="FAQ">
      <StyledContent>
        <StyledTitle>常見問題</StyledTitle>
        <div>
          <StyledText textAlign="left">1.Crypto Titans NFT 是什麼?</StyledText>
          <StyledText mb="24px" textAlign="left">
            Crypto Titans NFT 是給購買《精準捉住加密貨幣漲跌！》實體書讀者紀念用的限量NFT
          </StyledText>
          <StyledText textAlign="left">2. 如何獲取Crypto Titans NFT?</StyledText>
          <StyledText mb="24px" textAlign="left">
            你可以到各大線上及實體通路購買《精準捉住加密貨幣漲跌！》來獲得該NFT
          </StyledText>
          <StyledText textAlign="left">3. Crypto Titans NFT 支援哪些錢包?</StyledText>
          <StyledText mb="24px" textAlign="left">
            MetaMask, Wallet connect, OKX Web3 Wallet and JoyID Passkey Wallet.
          </StyledText>
        </div>
      </StyledContent>
    </StyledWrapper>
  </>
);

export default Sections;
