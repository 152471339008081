import styled from 'styled-components';
import { SM_AND_UP_QUERY } from '@/breakpoints';
import { Row, fontStyle } from '@/styles/common';
import { WebsiteBgImage, backgroundMobileImage, claimBackgroundImage } from '@/styles/imgMapping';

export const StyledZindex = styled.div`
  position: relative;
  z-index: 1;
`;

export const StyledBannerSection = styled.div`
  height: 850px;
  padding: 24px 42px;
  background: url(${backgroundMobileImage});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  margin-top: 59px;

  & > div {
    position: relative;
    z-index: 1;

    & > div:nth-child(1) {
      margin-bottom: 24px;
      color: ${({ theme }) => theme.colors.white};
      ${fontStyle('48px', '700')};
      font-family: 'Cormorant', serif;
      font-optical-sizing: auto;
      line-height: 57px;
    }

    & > div:nth-child(2) {
      margin-bottom: 24px;
      color: ${({ theme }) => theme.colors.white};
      ${fontStyle('16px', '400')};
      line-height: 30px;
    }

    & > div:nth-child(3) {
      color: ${({ theme }) => theme.colors.white};
      ${fontStyle('12px', '400')};
      line-height: 24px;
    }
  }

  @media ${SM_AND_UP_QUERY} {
    background: url(${WebsiteBgImage});
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    flex-direction: column;

    & > div {
      max-width: 1180px;
      width: 100%;
      margin-top: 155px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & > div {
        width: 366px;
      }

      & > div:nth-child(1) {
        ${fontStyle('64px', '700')};
        line-height: 76px;
      }

      & > div:nth-child(2) {
        ${fontStyle('20px', '400')};
      }

      & > div:nth-child(3) {
        ${fontStyle('16px', '400')};
      }
    }
  }
`;

export const StyledClaimSection = styled.div`
  padding: 85px 24px;
  background: url(${claimBackgroundImage});
  background-size: cover;
  background-position: 37% 50%;
  background-repeat: no-repeat;

  @media ${SM_AND_UP_QUERY} {
    background: url(${claimBackgroundImage});
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledClaimButton = styled.button`
  border: solid 2px #4c89ff;
  border-radius: 6px;
  width: 78px;
  background: #0000004d;
  color: #4c89ff;
  cursor: pointer;
  ${fontStyle('20px', '700')};
  padding: 10px 0;

  @media ${SM_AND_UP_QUERY} {
    width: 97px;
    padding: 13px 0;
  }
`;

export const StyledClaimTitle = styled.div`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.white};
  ${fontStyle('32px', '700')};
  font-family: 'Cormorant', serif;
  font-optical-sizing: auto;
  text-align: center;

  @media ${SM_AND_UP_QUERY} {
    ${fontStyle('40px', '700')};
  }
`;

export const StyledLinkRow = styled(Row)`
  justify-content: center;

  & > a:first-child {
    margin-right: 24px;
  }
`;

export const StyledClaimLink = styled.a`
  color: ${({ theme }) => theme.colors.white};
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.white};
  ${fontStyle('16px', '400')};
`;

export const StyledInput = styled.input`
  color: ${({ theme }) => theme.colors.white};
  border: solid 2px ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  padding: 15px 20px;
  background: #0000004d;
  text-align: center;
  font-size: 12px;
  flex: 1;
  margin-right: 8px;

  @media ${SM_AND_UP_QUERY} {
    padding: 15px 0;
    width: 462px;
    flex: auto;
    font-size: 16px;
    margin-right: 16px;
  }
`;
