import { connect } from '@joyid/evm';
import { OKXWallet } from '@okwallet/web3-react-okxwallet';
import { initializeConnector } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { chainMapping, walletRpcConnectConfig } from './evmWalletConfigs';

export enum SelectWallet {
  META_MASK = 'MetaMask',
  WALLET_CONNECT = 'WalletConnect',
  JOY_ID = 'JoyID Passkey Wallet',
  OKX = 'OKX Web3 Wallet',
}

const walletConnectId = process.env.REACT_APP_WALLET_CONNECT_ID;

export const [metaMask, metaMaskHooks] = initializeConnector<MetaMask>((actions) => new MetaMask({ actions }));

export const [okxWallet, okxHooks] = initializeConnector<any>((actions) => new OKXWallet({ actions }));

const [mainnet, ...optionalChains] = Object.keys(chainMapping).map((chainId) => Number(chainId));

export const [walletConnectV2, walletConnectV2Hooks] = initializeConnector(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: walletConnectId as string,
        rpcMap: walletRpcConnectConfig,
        chains: [mainnet],
        optionalChains,
        showQrModal: true,
      },
    }),
);

export const connecterList = ['metamask', 'walletConnect', 'joyid', 'okx'];

export const connecterConfigs: {
  [key: string]: {
    name: string;
    active: () => Promise<void>;
  };
} = {
  metamask: {
    name: SelectWallet.META_MASK,
    active: async () => await metaMask.activate(),
  },
  walletConnect: {
    name: SelectWallet.WALLET_CONNECT,
    active: async () => {
      try {
        localStorage.clear();
        await walletConnectV2.connectEagerly();
      } catch (error) {
        try {
          await walletConnectV2.activate();
        } catch (error) {
          // skip devlop error
        }
      }
    },
  },
  joyid: {
    name: SelectWallet.JOY_ID,
    active: async () => {
      await connect();
    },
  },
  okx: {
    name: SelectWallet.OKX,
    active: async () => {
      await okxWallet.activate();
    },
  },
};
