/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Row, StyledIconImg } from '@/styles/common';
import { linkedinIcon, subtackIcon, telegramIcon, xIcon } from '@/styles/imgMapping';
import { StyledContactWrapper, StyledCopyright, StyledText, StyledTitle, StyledWrapper, StyledZindex } from './Styles';

const Sections = () => (
  <StyledWrapper id="Contact">
    <StyledZindex>
      <StyledTitle>聯絡我們</StyledTitle>
      <StyledContactWrapper>
        <a href="https://www.linkedin.com/in/markus-thielen-513a35" target="_blank" rel="noreferrer">
          <Row>
            <StyledIconImg src={linkedinIcon} width="46px" mobileWidth="36px" />
            <StyledText>LinkedIn</StyledText>
          </Row>
        </a>
        <a href="https://t.me/MatrixOnTarget" target="_blank" rel="noreferrer">
          <Row>
            <StyledIconImg src={telegramIcon} width="46px" mobileWidth="36px" />
            <StyledText>Telegram</StyledText>
          </Row>
        </a>
        <a href="https://post.10xresearch.co" target="_blank" rel="noreferrer">
          <Row>
            <StyledIconImg src={subtackIcon} width="46px" mobileWidth="36px" />
            <StyledText>Substack</StyledText>
          </Row>
        </a>
        <a href="https://twitter.com/thielen10x" target="_blank" rel="noreferrer">
          <Row>
            <StyledIconImg src={xIcon} width="46px" mobileWidth="36px" />
            <StyledText>Twitter</StyledText>
          </Row>
        </a>
      </StyledContactWrapper>
      <StyledCopyright>Copyright © 2024 DOMIN NETWORK. All rights reserved.</StyledCopyright>
    </StyledZindex>
  </StyledWrapper>
);

export default Sections;
